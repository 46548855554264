/**
 * Created by airG on 2015-11-16.
 */
var reactions = (function(){
	var target = $('#reactions'),
		share = $('.share-detail'),
		media = '(max-width: 40em)', 
		labels = null, 
		postTitle = null;
	var root = null, 
		file = 'images/react-', 
		ext ='.png';

	// Symbolic constants:
	var MAX_REACTIONS = 5;

	target.click(function(e){
		var emojiTarget = $(e.target);
		$(this).find('.emoji').addClass("not-selected");		
		emojiTarget.removeClass("not-selected");
		emojiTarget.addClass("selected");
		share.slideDown();
		var tweetHtml = emojiTarget.next(".emoji-label").text();
		tweet(tweetHtml);

		//console.log($(this));
        //console.log($(e.currentTarget));
        //console.log($(e.target));
        //console.log(tweetHtml);
					
	});

	function tweet(reaction) {
		var tweetElm = $('#tweet');
		tweet.prevReaction = tweet.prevReaction || '';
		if (!reaction || !reaction.length || !tweetElm) { return; }
		// otherwise
		var emoji = getEmoji(reaction);
		
		if (emoji) {
			if (tweet.prevReaction && tweet.prevReaction.length) {
				tweetElm.attr('href', tweetElm.attr('href').replace(tweet.prevReaction, ''));				
			}
			tweet.prevReaction = encodeURI(reaction + " " + emoji + " ");
			tweetElm.attr('href', tweetElm.attr('href') + tweet.prevReaction + postTitle);
		}
		console.log(tweetElm);
		console.log(tweet.prevReaction);
	}
	function getEmoji(reaction_str) {
		if (!reaction_str || !reaction_str.length) { return null; }
		switch (reaction_str) {
			case "LOL":
				return "😂";
			case "WTF":
				return "😒";
			case "LOVE IT":
				return "😍";
			case "Eww":
				return "😖";
			case "OMG":
				return "😱";
			default:
				throw new Error("Reaction not yet supported", "reactions.js");
		}
	}

	/**
	 * Draw Reaction
	 * @param parent
	 * @param cssattr
	 * @param opts - where opts expects top props:
	 * 	1. target: DOM element
	 * 	2. val: for css
     */
	function draw(parent, cssattr, opts) {
		if (!parent) { return; }

		// otherwise
		$(parent).children().each(function(index, item){
			if (!opts || !opts.target) {
				$(item).css(cssattr, '');
				return;
			}

			// otherwise
			$(item).css(
				cssattr,
				item !== opts.target && opts.val ? opts.val : ''
			);
		});
	}

	function loadReactions() {
		for (var i=1; i <= MAX_REACTIONS; ++i) {
			var reaction = $('<div class="reaction"></div>');		
			var img = $('<div class="emoji"></div>');
			img.css(
				'background-image',
				'url(' + root + file + i + ext + ')'
			);
			reaction.append(img);
			if (labels && labels.length) {
				var span = $('<span class="emoji-label"></span>');
				span.html(labels[i - 1]);
				reaction.append(span);
			}
			target.append(reaction);
		}
	}

	function resetState() {
		//draw(target, cssAttr); // just reset children
		share.hide();
	}

	// public api
	return {
		/**
		 * Set static path and load reactions
		 * @param path - the static path to load reactions from
		 * @param strs - array of strings for each  reaction
         */
		load: function(path, strs) {
			if (path) { root = path; }
			var mq = matchMedia(media);
			mq.addListener(resetState);
			if (strs && Array.isArray(strs)) {
				labels = strs;
			}
			loadReactions();
		},

		setTitle: function(title) {
			if (title && title.length) {
				postTitle = title;
			}
		}
	}
}());